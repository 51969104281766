import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ENV } from '../../config/config';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { beforeCustomer, getCustomers } from '../Customers/Customers.action'
import { beforeProduct, getProducts } from '../Products/Products.action'
import { Button, Card, Form, Table, Container, Row, Col, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { getRole } from 'views/AdminStaff/permissions/permissions.actions';
import { beforeSale, getOrders } from './Sale.action';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { currencyFormat } from 'utils/functions';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faUsers, faCheckCircle, faCross, faBan } from '@fortawesome/free-solid-svg-icons'
var CryptoJS = require("crypto-js");
import { Link } from "react-router-dom";

const Orders = (props) => {
    const history = useHistory()

    const [orders, setOrders] = useState([])
    const [ordersStats, setOrdersStats] = useState([])
    const [pagination, setPagination] = useState(null)
    const [Page, setPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [permissions, setPermissions] = useState({})


    const [orderNumber, setOrderNumber] = useState('')
    const [customer, setCustomer] = useState('')
    const [invoiceStatus, setInvoiceStatus] = useState('')
    const [invoiceStatusOptions, setInvoiceStatusOptions] = useState([
        {
            label: 'Select Invoice Status',
            value: ''
        },
        {
            label: 'Not Invoiced',
            value: '0'
        },
        {
            label: 'Invoiced',
            value: 1
        }
    ])
    const [orderStatus, setOrderStatus] = useState(window.location.pathname.split('/')[3])
    const [orderStatusOptions, setOrderStatusOptions] = useState([
        {
            label: 'Select Order Status',
            value: ''
        },
        {
            label: 'Order Received',
            value: '0'
        },
        {
            label: 'Processing',
            value: '1'
        },
        {
            label: 'On The Way',
            value: '2'
        },
        {
            label: 'Delivered',
            value: '3'
        },
        {
            label: 'Cancelled',
            value: '4'
        },
    ])
    const [product, setProduct] = useState()

    const [paymentType, setPaymentType] = useState('')

    const [customerOptions, setCustomerOptions] = useState([])
    const [productOptions, setProductOptions] = useState([{ value: '', label: 'Select Customerw' }])
    useEffect(() => {
        window.scroll(0, 0)
        const qs = ENV.objectToQueryString({ page: Page, limit: 10 })
        const filter = {}
        if (orderNumber)
            filter.orderNumber = orderNumber
        if (customer)
            filter.customer = customer
        if (paymentType)
            filter.paymentMethod = paymentType
        if (invoiceStatus)
            filter.isInvoiced = invoiceStatus
        if (orderStatus == 0 || orderStatus == 1 || orderStatus == 2 || orderStatus == 3)
            filter.orderStatus = orderStatus
        if (product) {
            filter.productID = product
        }
        props.getOrders(qs, filter)
        let roleEncrypted = localStorage.getItem('role');
        let role = ''
        if (roleEncrypted) {
            let roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, 'secret key 123').toString(CryptoJS.enc.Utf8);
            role = roleDecrypted
        }
        props.getRole(role)

        const qsC = ENV.objectToQueryString({ all: 1 })
        props.getCustomers(qsC)
        props.getProducts(qsC);

    }, [])
    useEffect(() => {
        if (props.product.getProductsAuth) {
            const { products, pagination } = props.product.productsList
            let prodArray = [{ value: '', label: 'Select Customer' }]
            products.forEach((prod, index) => {
                prodArray.push({
                    value: prod._id,
                    key: prod._id,
                    label: prod.title,
                })
            })
            setProductOptions(prodArray)
            props.beforeProduct()
        }
    }, [props.product.getProductsAuth])                     // Products Fetched

    useEffect(() => {
        if (props.customer.getCustomerAuth) {
            const { customers, pagination } = props.customer
            let customers_ = [{ value: '', label: 'Select Customer' }]
            customers.forEach((customer, index) => {
                customers_.push({
                    label: customer.customername + ' / ' + customer.email,
                    key: customer._id,
                    value: customer._id,

                })
            })
            setCustomerOptions(customers_)
            props.beforeCustomer()
        }
    }, [props.customer.getCustomerAuth])


                     // Customers Fetched
    useEffect(() => {
        if (Object.keys(props.getRoleRes).length > 0) {
            setPermissions(props.getRoleRes.role)
        }
    }, [props.getRoleRes])                                  // Roles Fetched

    useEffect(() => {
        if (props.sale.getOrdersAuth) {
            let { orders, pagination, ordersStats } = props.sale.orders
            setOrders(orders)
            setLoader(false)
            setPagination(pagination)
            setOrdersStats(ordersStats)
            props.beforeSale()
        }
    }, [props.sale.getOrdersAuth]);                         // Orders Fetched

    const onPageChange = async (page) => {
        const filter = {}
        if (orderNumber)
            filter.orderNumber = orderNumber
        if (customer)
            filter.customer = customer
        if (paymentType)
            filter.paymentMethod = paymentType
        if (invoiceStatus)
            filter.isInvoiced = invoiceStatus
        if (orderStatus)
            filter.orderStatus = orderStatus
        if (product) {
            filter.productID = product.split('____')[0]
        }



        setPage(page)
        setLoader(true)
        const qs = ENV.objectToQueryString({ page: page, limit: 10 })
        props.getOrders(qs, filter)
    }

    const applyFilters = () => {
        const filter = {}
        // if (orderNumber)
        //     filter.orderNumber = orderNumber
        if (orderNumber) {
            filter.orderNumber = orderNumber.trim().replace(/^SC/, '').replace(/^0+/, '');
        }
        if (customer)
            filter.customer = customer
        if (paymentType)
            filter.paymentMethod = paymentType

        if (invoiceStatus)
            filter.isInvoiced = invoiceStatus
        if (orderStatus)
            filter.orderStatus = orderStatus

        if (product) {
            filter.productID = product.split('____')[0]
        }

        const qs = ENV.objectToQueryString({ page: 1, limit: 10 })
        props.getOrders(qs, filter)
        setLoader(true)
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            applyFilters();
        }
    }
    const reset = () => {

        setOrderNumber('')
        setCustomer('')
        setInvoiceStatus('')
        setPaymentType('')
        setProduct('')
        setOrderStatus('')

        const qs = ENV.objectToQueryString({ page: 1, limit: 10 })
        props.getOrders(qs, {})
        setLoader(true)
    }
    return (
        <>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <Container fluid>
                        <Row className="pb-3">
                            <Col sm={12}>
                                {
                                    ordersStats.length > 0 &&
                                    <Row>
                                        <Col xl={4} lg={4} sm={6}>
                                            <Card className="card-stats custom-card" onClick={() => {
                                                setOrderStatus('1')
                                                setTimeout(function () {
                                                    if (orderStatus == '1') {
                                                        applyFilters()
                                                    }
                                                }, 2000);
                                            }}>
                                                <Card.Body>
                                                    <div className="d-flex">
                                                        <div className="numbers">
                                                            <p className="card-category">Confirmed Orders</p>
                                                            <Card.Title as="h4">{ordersStats[1] ? ordersStats[1] : 0}</Card.Title>
                                                        </div>
                                                        <div className="icon-big text-center icon-warning">
                                                            <FontAwesomeIcon icon={faCheckCircle} />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xl={4} lg={4} sm={6}>
                                            <Card className="card-stats custom-card" onClick={() => {
                                                setOrderStatus('4')
                                                setTimeout(function () {
                                                    if (orderStatus == '4') {
                                                        applyFilters()
                                                    }
                                                }, 2000);
                                            }}>
                                                <Card.Body>
                                                    <div className="d-flex">
                                                        <div className="numbers">
                                                            <p className="card-category">Cancelled Orders</p>
                                                            <Card.Title as="h4">{ordersStats[4] ? ordersStats[4] : 0}</Card.Title>
                                                        </div>
                                                        <div className="icon-big text-center icon-warning">
                                                            <FontAwesomeIcon icon={faBan} />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>

                        <Row className="pb-3">
                            <Col sm={12}>
                                <Card className="filter-card custom">
                                    <Card.Header>
                                        <div className="d-block d-md-flex align-items-center justify-content-between">
                                            <Card.Title as="h4">Filters</Card.Title>
                                            {/* <p className="card-collection">List of Auctions</p> */}
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xl={3} sm={6}>
                                                <Form.Group>
                                                    <label style={{ color: 'black' }}>Order Number</label>
                                                    <Form.Control onKeyPress={handleKeyPress} type="text" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} placeholder='Order No' />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={3} sm={6}>
                                                <Form.Group>
                                                    <label style={{ color: 'black' }}>Customer</label>
                                                    {customerOptions.length > 0 ?
                                                        <Select onKeyPress={handleKeyPress} options={customerOptions}
                                                            onChange={(event) => setCustomer(event.value)}
                                                            value={customerOptions.filter(option => option.value === customer)}
                                                        />
                                                        : ''
                                                    }
                                                </Form.Group>
                                            </Col>


                                            <Col xl={3} sm={6}>
                                                <label style={{ color: 'black' }}>Product</label>

                                                <Form.Group>

                                                    {productOptions.length > 0 ?
                                                        <Select onKeyPress={handleKeyPress} options={productOptions}
                                                            onChange={(event) => setProduct(event.value)}
                                                            value={productOptions.filter(option => option.value === product)}
                                                        />
                                                        : ''
                                                    }
                                                </Form.Group>

                                            </Col>
                                            <Col xl={3} sm={6}>
                                                <label style={{ color: 'black' }}>Invoice Status</label>
                                                <Form.Group>
                                                    <Select options={invoiceStatusOptions}
                                                        onChange={(event) => setInvoiceStatus(event.value)}
                                                        value={invoiceStatusOptions.filter(option => option.value === invoiceStatus)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={3} sm={6}>
                                                <label style={{ color: 'black' }}>Order Status</label>
                                                <Form.Group>
                                                    <Select onKeyPress={handleKeyPress} options={orderStatusOptions}
                                                        onChange={(event) => setOrderStatus(event.value)}
                                                        value={orderStatusOptions.filter(option => option.value === orderStatus)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={3} sm={6}>
                                                <Form.Group className='btnGroup'>
                                                    <Form.Label className="d-block">&nbsp;</Form.Label>
                                                    <div className="d-flex filter-btns-holder">
                                                        <Button variant="info" disabled={!orderNumber && !orderStatus && !orderNumber && !customer && !invoiceStatus && !paymentType && !product} onClick={applyFilters}>Search</Button>
                                                        <Button variant="warning" hidden={!orderNumber && !orderStatus && !orderNumber && !customer && !invoiceStatus && !paymentType && !product} onClick={reset}>Reset</Button>
                                                    </div>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Card className="filter-card">
                                    <Card.Header>
                                        <div className="d-block d-md-flex align-items-center justify-content-between">
                                            <span style={{ color: 'white', fontWeight: 'bold' }}>{`Total : ${pagination?.total}`}</span>
                                        </div>
                                        <div className="d-block d-md-flex align-items-center justify-content-between">
                                            <Card.Title as="h4">Orders</Card.Title>
                                            {
                                                permissions && permissions.addOrder &&
                                                <Button
                                                    variant="info"
                                                    className="float-sm-right"
                                                    onClick={() => props.history.push(`/create-new-sale`)}>
                                                    New Order
                                                </Button>
                                            }
                                        </div>
                                    </Card.Header>

                                    <Card.Body className="table-full-width">

                                        <div className="table-responsive">
                                            <Table className="table-bigboy">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center serial-col">#</th>
                                                        <th >Order Number</th>
                                                        <th >Customer</th>
                                                        <th >Order Date</th>
                                                        <th >Total</th>
                                                        <th>Payment Method</th>
                                                        <th >Status</th>
                                                        <th className="td-actions text-center">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        orders && orders.length ?
                                                            orders.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="text-center serial-col">{pagination && ((pagination.limit * pagination.page) - pagination.limit) + index + 1}</td>

                                                                        <td>  <Link to={`/edit-sale/${item._id}`}>{"SC" + item.orderNumber.toString().padStart(5, 0)}</Link></td>

                                                                        <td>
                                                                            {item.customer[0]?.customername + " (" + item.customer[0]?.email + ")"}
                                                                        </td>
                                                                        <td>
                                                                            {moment(item.createdAt).format("MM-DD-YYYY")}
                                                                        </td>
                                                                        <td>
                                                                            {currencyFormat(parseFloat(item.grandTotal).toFixed(2))}
                                                                        </td>
                                                                        <td>
                                                                            {item.transactionPlatform}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                {
                                                                                    "0": <span bg="warning" class="badge badge-warning">Order Received</span>,
                                                                                    "1": <span bg="success" class="badge badge-success">Processing</span>,
                                                                                    "2": <span bg="danger" class="badge badge-danger">On the Way</span>,
                                                                                    "3": <span bg="success" class="badge badge-success">Delivered</span>,
                                                                                    "4": <span bg="danger" class="badge badge-danger">Cancelled</span>,
                                                                                }[item?.status?.toString()]
                                                                            }
                                                                        </td>

                                                                        <td className="td-actions text-center">
                                                                            <ul className="list-unstyled mb-0">
                                                                                {
                                                                                    permissions && permissions.editOrder &&

                                                                                    <li className="d-inline-block align-top">
                                                                                        <Button
                                                                                            className="btn-action btn-warning"
                                                                                            type="button"
                                                                                            variant="success" title="Edit"
                                                                                            onClick={() => props.history.push(`/edit-sale/${item._id}`)}
                                                                                        >
                                                                                            <i className="fas fa-edit"></i>
                                                                                        </Button>
                                                                                    </li>
                                                                                }


                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan="7" className="text-center">
                                                                    <div className="alert alert-info" role="alert">No Orders Found</div>
                                                                </td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            {
                                                pagination &&
                                                <Pagination
                                                    className="m-3"
                                                    defaultCurrent={1}
                                                    pageSize // items per page
                                                    current={Page > pagination.pages ? pagination.pages : Page} // current active page
                                                    total={pagination.pages} // total pages
                                                    onChange={onPageChange}
                                                    locale={localeInfo}
                                                />
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
            }
        </>
    )
}

const mapStateToProps = state => ({
    sale: state.sale,
    error: state.error,
    getRoleRes: state.role.getRoleRes,
    customer: state.customer,
    product: state.product
});

export default connect(mapStateToProps, { beforeSale, getRole, getOrders, beforeCustomer, getCustomers, beforeProduct, getProducts })(Orders);