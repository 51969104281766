import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { beforeProduct , getProduct, getVariations, updateVariation, deleteVariation, addVariation } from '../Products.action';
import { ENV } from '../../../config/config';
import 'rc-pagination/assets/index.css';
import { Table, OverlayTrigger, Button, Tooltip, Modal, Row, Form, Col, Card } from "react-bootstrap";
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { currencyFormat } from 'utils/functions';
import Swal from 'sweetalert2';


const VariationListings = (props) => {
    const [productVariations, setProductVariations] = useState([])
    const [product, setProduct] = useState([])
    const [variation, setVariation] = useState(null)
    const [variationModal, setVariationModal] = useState(false)
    const [variationAddModal, setVariationAddModal] = useState(false)
    const [newVariation, setNewVariation] = useState(null)
    const [loader, setLoader] = useState(true)
    


    useEffect(() => {
        window.scroll(0, 0)
        setLoader(true)
        const qs = ENV.objectToQueryString({ all: 1 })
        props.getVariations(props.productId)
       
        
    }, []);

    useEffect(() => {
        if (props.product.getVariationsAuth) {
            setLoader(false)
            console.log(props.product)
            setProductVariations(props.product.variations)
            setProduct(props.product.product)
            setNewVariation({
                productId: props.productId,
                price: props.product.product.price,
                size: "",
                color: "",
                colorCode: ""
            })
            props.beforeProduct()
            setLoader(false)
        }
    }, [props.product.getVariationsAuth])            // Variations Fetched

    useEffect(() => {
        if (props.product.delVariationsAuth) {
            props.getVariations(props.productId)

        }
    }, [props.product.delVariationsAuth])      // Variation Deleted

    useEffect(() => {
        if (props.product.upsertVariationsAuth) {
            setLoader(false)
            setVariationModal(false)
            setVariation(null)
            props.getVariations(props.productId)
        }
    }, [props.product.upsertVariationsAuth]);
    
    useEffect(() => {
        if (props.product.addVariationsAuth) {
            setLoader(false)
            props.getVariations(props.productId)
        }
    }, [props.product.addVariationsAuth]);

    const deleteVariations = (variationId) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            html: 'If you delete an item, it would be permanently lost.',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then(async (result) => {
            if (result.value) {
                setLoader(true)
                props.deleteVariation(variationId)
            }
        })
    }
    

    const openModal = (variationId) => {
        let selectedVariation = productVariations.filter(function (elem) {
            return elem._id == variationId
        })
        selectedVariation[0].variationName = product.name + ' '

        let detailNameArray = selectedVariation[0].details.map((value, vIndex) => {
            return value.title + ": " + value.value
        })
        selectedVariation[0].title = product.title+' ( ' + detailNameArray.join(', ') + " )"


        setVariation(selectedVariation[0])
        setVariationModal(true)
    }
    const handleChange = (name, value) => {
        setVariation({ ...variation, [name]: value })
    }
    const editVariation = (_id) => {
        props.updateVariation(variation)
    }

    const submitNewVariation = (  ) => {
        let payload = {
            productId: newVariation.productId,
            price: newVariation.price,
            details: [
                {
                    title: "Color",
                    isColor: true,
                    isMeasurement: false,
                    isImage: false,
                    value: newVariation.color,
                    image: "",
                    colorCode: newVariation.colorCode,
                    measurementScale: "",
                },
                {
                    title: "Size",
                    isColor: false,
                    isMeasurement: false,
                    isImage: false,
                    value: newVariation.size,
                    image: "",
                    colorCode: "",
                    measurementScale: "",
                }
            ]
        }
        setLoader(true)
        props.addVariation(payload)
        console.log(payload)
    }
    return (
        <>
            {

                loader ?
                    <FullPageLoader/>
                :
                <>
                    <Button
                        variant="info"
                        className="float-sm-right"
                        onClick={() => setVariationAddModal(true)}>
                        Add Variation
                    </Button>
                    <div className="table-responsive">
                        <Table className="table-bigboy">

                            <thead>
                                <tr>
                                    <th className="text-center serial-col">#</th>
                                    <th className="text-center">Variation Name</th>
                                    <th className="text-center">Price</th>
                                    <th className="td-actions text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    productVariations && productVariations.length ?
                                        productVariations.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-center serial-col">{index + 1}</td>
                                                    <td className="text-center">
                                                        {product.title} &nbsp;

                                                        (
                                                        {
                                                            item.details.map((value, vIndex) => {
                                                                return (
                                                                    <> <strong>{value.title}:</strong> {value.value} </>
                                                                )
                                                            })
                                                        }
                                                        )

                                                    </td>
                                                    <td className="text-center">{currencyFormat(item.price)}</td>
                                                    <td className="td-actions text-center">
                                                        <ul className="list-unstyled mb-0">
                                                            <li className="d-inline-block align-top">
                                                                <a
                                                                    className="btn-action btn-warning"
                                                                    type="button"
                                                                    variant="info" title="Edit"
                                                                    onClick={() => openModal(item._id)}
                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </a>
                                                            </li>

                                                            <li className="d-inline-block align-top">
                                                                <OverlayTrigger overlay={() => (<Tooltip id="tooltip-481441726">Delete</Tooltip>)} >
                                                                    <Button
                                                                        className="btn-action btn-danger"
                                                                        type="button"
                                                                        variant="danger"
                                                                        onClick={() => deleteVariations(item._id)}
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan="7" className="text-center">
                                                <div className="alert alert-info" role="alert">No Product Variation Found</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                        {
                            variationModal && variation &&
                            <Modal className="modal-primary" onHide={() => setVariationModal(!variationModal)} show={variationModal}>
                                <Modal.Header className="justify-content-center">
                                    <Row>
                                        <div className="col-12">
                                            <h4 className="mb-0 mb-md-3 mt-0">
                                                Product Variant Information
                                            </h4>
                                        </div>
                                    </Row>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form className="text-left">
                                        <Form.Group>
                                            <label>Variation Name</label>
                                            <Form.Control
                                                disabled={true}
                                                type="text"
                                                name=""
                                                value={variation.title}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <label>price</label>
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                name=""
                                                onChange={(e) => handleChange('price', e.target.value)}
                                                value={variation.price}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn btn-warning" onClick={() => {
                                        setVariationModal(!variationModal)
                                        setVariation(null)
                                    }}>Close</Button>
                                    <Button className="btn btn-info" onClick={() => editVariation(variation._id)} >Update</Button>

                                </Modal.Footer>
                            </Modal>
                        }
                        {
                            variationAddModal  &&
                            <Modal className="modal-primary" onHide={() => setVariationAddModal(!variationAddModal)} show={variationAddModal}>
                                <Modal.Header className="justify-content-center">
                                    <Row>
                                        <div className="col-12">
                                            <h4 className="mb-0 mb-md-3 mt-0">
                                                Add Product Variation
                                            </h4>
                                        </div>
                                    </Row>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form className="text-left">
                                        <Form.Group>
                                            <label>Size</label>
                                            <Form.Control
                                                type="text"
                                                name=""
                                                onChange={(e) => setNewVariation({...newVariation, size: e.target.value})}
                                                // onChange={(e) => handlenewVariationChange('size', e.target.value)}
                                                value={newVariation.size}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <label>Color</label>
                                            <Form.Control
                                                type="text"
                                                name=""
                                                onChange={(e) => setNewVariation({...newVariation, color: e.target.value})}
                                                // onChange={(e) => handlenewVariationChange('color', e.target.value)}
                                                value={newVariation.color}
                                            />
                                            <Form.Control
                                                type="color"
                                                name=""
                                                onChange={(e) => setNewVariation({...newVariation, colorCode: e.target.value})}
                                                // onChange={(e) => handlenewVariationChange('colorCode', e.target.value)}
                                                value={newVariation.colorCode}
                                                />
                                        </Form.Group>
                                        <Form.Group>
                                            <label>Price</label>
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                name=""
                                                onChange={(e) => setNewVariation({...newVariation, price: e.target.value})}
                                                // onChange={(e) => handlenewVariationChange('price', e.target.value)}
                                                value={newVariation.price}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn btn-warning" onClick={() => {
                                        setVariationAddModal(!variationAddModal)
                                    }}>Close</Button>
                                    <Button className="btn btn-info" onClick={() => { submitNewVariation() }}>Add</Button>

                                </Modal.Footer>
                            </Modal>
                        }
                    </div>
                </>

            }
        </>
    )
}

const mapStateToProps = state => ({
    product: state.product,
    error: state.error
});

export default connect(mapStateToProps, { beforeProduct , getProduct, getVariations, updateVariation, deleteVariation, addVariation  })(VariationListings);