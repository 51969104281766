import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ENV } from '../../../config/config';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import Swal from 'sweetalert2';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { addStaffAdmin, updateAdmin, getUserVerify, beforeVerify } from 'views/Admin/Admin.action';
import $ from 'jquery';
import { Button, Card, Form, Table, Container, Row, Col, OverlayTrigger, Tooltip, Modal, FormGroup } from "react-bootstrap";
var CryptoJS = require("crypto-js");

const StaffPermissionModal = (props) => {

    const dispatch = useDispatch()
    const [userId, setUserId] = useState('')
    const [roleId, setRole] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [admin, setAdmin] = useState('')
    const [password, setPasssword] = useState('')
    const [confirmPassword, setConfirmPass] = useState('')
    const [status, setStatus] = useState(true)
    const [showPassword, setShowPassword] = useState(false)

    const [roles, setRoles] = useState({})
    const [phone, setPhone] = useState('')
    const [nameMsg, setNameMsg] = useState('')
    const [phoneMsg, setPhoneMsg] = useState('')
    const [emailMsg, setEmailMsg] = useState('')
    const [passwordMsg, setPassMsg] = useState('')
    const [roleMsg, setRoleMsg] = useState('')
    const [confirmPassMsg, setConfirmPassMsg] = useState('')

    const addAdminRes = useSelector(state => state.admin.addAdminRes)
    const updateAdminRes = useSelector(state => state.admin.updateAdminRes)

    useEffect(() => {
        if (Object.keys(addAdminRes).length > 0) {
            props.setModalType(1)
            setEmpty()
            if (addAdminRes.success) {
                toast.success(addAdminRes.message)
            }
            else {
                toast.error(addAdminRes.message)
            }


        }
    }, [addAdminRes])

    // useEffect(() => {
    //     if(name || email || phone || password || confirmPassword || roleId){
    //         validate()
    //     }
    // }, [name, email, phone, password, confirmPassword, roleId])

    useEffect(() => {
        if (Object.keys(updateAdminRes).length > 0) {
            // props.setroleModal(!props.roleModal)
            props.setModalType(1)
            setEmpty()
            // props.setLoader(false)
            // props.getData()
        }
    }, [updateAdminRes])

    useEffect(() => {
        if (Object.keys(props.admin).length > 0) {
            if (props.modalType === 1) {
                setEmpty()
            }
            else if (props.modalType === 2 || props.modalType === 3) {
                setUserId(props.admin._id)
                setName(props.admin.name)
                setRole(props.admin.roleId)
                setEmail(props.admin.email)
                setPhone(props.admin.phone)
                setStatus(props.admin.status)
                // setPasssword(props.admin.password)
            }

        }
    }, [props.admin])

    useEffect(() => {
        if (props.roles) {
            setRoles(props.roles)
        }
    }, [props.roles])


    const setEmpty = () => {
        setName('')
        setRole('')
        setEmail('')
        setPasssword('')
        setConfirmPass('')
        setPhone('')
        setStatus(true)

        setNameMsg('')
        setRoleMsg('')
        setEmailMsg('')
        setPassMsg('')
        setPhoneMsg('')
        setConfirmPassMsg('')
    }

    console.log("name mesg", nameMsg)
    const validate = (e) => {

        let check = true

        let trimName = name.trim().length
        if (trimName < 3) {
            setNameMsg('Name is Required.')
            check = false
        }
        if (!validator.isEmpty(name.trim()) && name.trim().length >= 3 && name.trim().length <= 35) {
            setNameMsg('')
        } else {

            setNameMsg('Name charachter length must be between 3 and 35.')
            check = false

        }
        if (validator.isEmpty(roleId)) {
            setRoleMsg('Please select a role')
            check = false
        }
        if (!validator.isEmpty(roleId)) {
            setRoleMsg('')
        }
        if (validator.isEmpty(email)) {
            setEmailMsg('Email is Required.')
            check = false
        }
        else {
            if (!validator.isEmail(email)) {
                setEmailMsg('Please enter a valid email address')
                check = false
            }
            else {
                setEmailMsg('')
            }
        }
        if (!validator.isEmpty(phone)) {
            const numericValue = phone.replace(/[^0-9+]/g, '');
            if (numericValue.length < 7 || numericValue.length > 15) {
                setPhoneMsg('Phone number must be between 7 and 15 digits.')
                check = false
                
            } else if (numericValue[0] === '0' || (numericValue[0] !== '+' && numericValue[0] === '0')) {
                setPhoneMsg('Phone number cannot start with zero')
                check = false

            }
            else {
                setPhoneMsg('')
            }
        }
        else {
            setPhoneMsg('')
        }
        if (props.modalType === 3 && (!validator.isEmpty(confirmPassword) || !validator.isEmpty(password))) {
            if (!validator.equals(password, confirmPassword) && props.modalType === 3) {
                setConfirmPassMsg('Passwords do not match')
                check = false
            }
            else {
                setPassMsg('')
            }
        }
        return check
    }

    const submit = (e) => {
        let check = validate()
        if (check) {

            let payload = { name, email, password, status, phone, roleId }

            if (props.modalType === 1) { // add modal type

                props.setLoader(true)
                let payload = { name, email, password: Math.floor(100000 + Math.random() * 900000), status, phone, roleId }
                dispatch(addStaffAdmin(payload));

            }
            else if (props.modalType === 3) { // update modal type

                props.setLoader(true)

                let payload = { name, email, password, status, phone, roleId }

                payload._id = userId
                dispatch(updateAdmin(payload));
                props.setAdminType(false)
            }
            props.setLoader(true)
            props.getData(payload)
            props.setroleModal(!props.roleModal)

        }
        else {
            console.log("sb,it else")
            $('#modal-primary').scrollTop(0, 0)
        }

    }

    const onCloseHandler = () => {
        props.setroleModal(!props.roleModal)
        setEmpty()
    }
    const [showConfirmPassword, setConfirmPassword2] = useState(false);

    const showPasswordMethod = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword)
    }
    const showConfirmPasswordMethod = (e) => {
        e.preventDefault();
        setConfirmPassword2(!showConfirmPassword)
    }
    return (
        <Container fluid>
            {
                props.modalType > 0 &&
                <Modal className="modal-primary staff-modal" id="admin-modal" onHide={() => { onCloseHandler(); props.setAdmin({}) }} show={props.roleModal}>
                    <Modal.Header className="justify-content-center">
                        <Row>
                            <div className="col-12">
                                <h4 className="mb-0 mb-md-3 mt-0">
                                    {props.modalType === 1 ? 'Add New' : props.modalType === 2 ? 'View' : 'Edit'} Staff
                                </h4>
                            </div>
                        </Row>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <Form>
                            <Form.Group>
                                <label>Full Name <span className="text-danger">*</span></label>
                                <Form.Control
                                    placeholder="Enter name"
                                    disabled={props.modalType === 2}
                                    type="text"
                                    name="name"
                                    minLength={3}
                                    maxLength={35}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setNameMsg('' )
                                    }}  
                                    value={name}
                                    required
                                />
                                <span className={nameMsg ? `` : `d-none`}>
                                    <label className="pl-1 text-danger">{nameMsg}</label>
                                </span>

                            </Form.Group>

                            <Form.Group>
                                <label>User Role <span className="text-danger">*</span></label>
                                <Form.Control as="select" className="form-select pr-3 mr-3" aria-label="Default select example" name="roleId" disabled={props.modalType === 2} value={roleId} onChange={(e) => {
                                    setRole(e.target.value)
                                    setRoleMsg('')
                                }} >
                                    <option value={''}>Select Role</option>
                                    {
                                        props.roles ?
                                            props.roles.length > 0 ?
                                                props.roles.map((role, key) => {
                                                    if (role.title != 'super admin' && role.status == true)
                                                        return (<option key={key} value={role._id}>{role.title}</option>);
                                                }) : <option value='' disabled>No role found</option>
                                            : ''
                                    }
                                </Form.Control>
                                <span className={roleMsg ? `` : `d-none`}>
                                    <label className="pl-1 text-danger">{roleMsg}</label>
                                </span>
                            </Form.Group>

                            <Form.Group>
                                <label>Email <span className="text-danger">*</span></label>
                                <Form.Control
                                    placeholder="xyz@example.com"
                                    disabled={props.modalType === 2}
                                    type="text"
                                    name="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setEmailMsg("")
                                    }}
                                    value={email}
                                    required
                                />
                                <span className={emailMsg ? `` : `d-none`}>
                                    <label className="pl-1 text-danger">{emailMsg}</label>
                                </span>

                            </Form.Group>
                            {
                                props.modalType === 3 ?
                                    <FormGroup>
                                        <Form.Group>
                                            <label>Password <span className="text-danger">{props.modalType === 1 ? '*' : ''}</span></label>
                                            <div className="form-password-eye-box">
                                                <Form.Control
                                                    placeholder="Password"
                                                    disabled={props.modalType === 2}
                                                    type={showPassword ? "text" : "password"}
                                                    autoComplete="off"
                                                    name="password"
                                                    onChange={(e) => setPasssword(e.target.value)}
                                                    value={password}
                                                    required
                                                />
                                                <button onClick={(e) => showPasswordMethod(e)} className="form-password-eye">
                                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                                </button>
                                            </div>
                                            <span className={passwordMsg ? `` : `d-none`}>
                                                <label className="pl-1 text-danger">{passwordMsg}</label>
                                            </span>

                                        </Form.Group>

                                        <Form.Group>
                                            <label>Confirm Password <span className="text-danger">{props.modalType === 1 ? '*' : ''}</span></label>
                                            <div className="form-password-eye-box">
                                                <Form.Control
                                                    placeholder="Confirm Password"
                                                    disabled={props.modalType === 2}
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    autoComplete="off"
                                                    name="confirmPassword"
                                                    onChange={(e) => setConfirmPass(e.target.value)}
                                                    value={confirmPassword}
                                                    required
                                                />
                                                <button onClick={(e) => showConfirmPasswordMethod(e)} className="form-password-eye">
                                                    <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
                                                </button>
                                            </div>
                                            <span className={confirmPassMsg ? `` : `d-none`}>
                                                <label className="pl-1 text-danger">{confirmPassMsg}</label>
                                            </span>

                                        </Form.Group>
                                    </FormGroup>
                                    :
                                    null
                            }



                            <Form.Group>
                                <label>Phone Number</label>
                                <Form.Control
                                    placeholder="+1234567890"
                                    disabled={props.modalType === 2}
                                    type="text"
                                    name="phone"
                                    onChange={(e) =>{ 
                                        setPhone(e.target.value)
                                        setPhoneMsg('' )
                                    }}
                                    value={phone}
                                    maxLength={11}

                                />
                                <span className={phoneMsg ? `` : `d-none`}>
                                    <label className="pl-1 text-danger">{phoneMsg}</label>
                                </span>
                            </Form.Group>
                            <FormGroup >

                                {/* <label className="right-label-checkbox">Select All
                                                <input type="checkbox" name="selectAll" disabled = {props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !selectAll)} checked={selectAll} />
                                                <span className="checkmark"></span>
                                            </label> */}
                                <label className='d-block'>Status</label>
                                <label className={`right-label-radio mr-3 mb-2 ${props.modalType === 2 ? 'disabled-checkbox' : ''}`}>

                                    <div > <input disabled={props.modalType === 2} name="status" type="checkbox" checked={status} value={status} onChange={(e) => setStatus(true)} />
                                        <span className="checkmark"></span>
                                    </div>

                                    <span className='ml-2 d-inline-block' onChange={(e) => setStatus(true)} ><i />Active

                                    </span>
                                </label>
                                <label className={`right-label-radio mr-3 mb-2 ${props.modalType === 2 ? 'disabled-checkbox' : ''}`}>
                                    <span className="checkmark"></span>
                                    <div> <input disabled={props.modalType === 2} name="status" type="checkbox" checked={!status} value={!status} onChange={(e) => setStatus(false)} />
                                        <span className="checkmark"></span>
                                    </div>
                                    <span className='ml-2' onChange={(e) => setStatus(false)} ><i />Inactive</span>
                                </label>
                            </FormGroup>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className="btn btn-warning" onClick={() => { onCloseHandler(); props.setAdmin({}) }}>Close</Button>
                        {
                            props.modalType === 2 ? '' :
                                <Button className="btn btn-info" onClick={() => { submit(); props.setAdmin({}) }} /* disabled={isLoader} */>Save</Button>
                        }
                    </Modal.Footer>
                </Modal>
            }
        </Container>

    )
}


export default StaffPermissionModal;