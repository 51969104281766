import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import FullPageLoader from 'components/FullPageLoader/FullPageLoader'
import 'rc-pagination/assets/index.css'
import { Button, Card, Form, Table, Container, Row, Col, Modal } from "react-bootstrap"
import { Link } from 'react-router-dom'
import { beforeSale, getInvoice, registerPayment } from '../Sales/Sale.action'
import { ENV } from '../../config/config';
import { currencyFormat } from '../../../src/utils/functions'
import Select from "react-select";
import validator from 'validator';
import { toast } from 'react-toastify';


const Invoice = (props) => {

    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const orderId = window.location.pathname.split('/')[3]


    useEffect(() => {
        window.scroll(0, 0)
        props.getInvoice(window.location.pathname.split('/')[3])
    }, [])


    useEffect(() => {
        if (props.sale.getOrderAuth) {
            setData(props.sale.order)
            props.beforeSale()
            setLoader(false)
        }
    }, [props.sale.getOrderAuth]);  // Order Fetched


    //Static Value of Color

    const color = [
        {
            value: 1,
            label: "Black & White",
        },
        {
            value: 2,
            label: "Colored",
        },
        {
            value: 3,
            label: "Mixed",
        },
    ]
    //Static Value of Body

    const bodyParts = [
        {
            value: 1,
            label: "Left Arm",
        },
        {
            value: 2,
            label: "Right Arm",
        },
        {
            value: 3,
            label: "Chest",
        },
        {
            value: 4,
            label: "Neck",
        },
        {
            value: 5,
            label: "Back",
        },
        {
            value: 6,
            label: "Left Leg",
        },
        {
            value: 7,
            label: "Right Leg",
        },
        {
            value: 9,
            label: "Wrist"
        }
    ]

    const getMatchValue = (value, object) => {
        const matchingObject = object.filter(item => item.value === value);
        const matchingValue = matchingObject.map(item => item.label);
        return matchingValue;
    }

    return (
        <>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <Container>
                        <Row>
                            <Col md="12">
                                <Card className="pb-3 table-big-boy">
                                    <Card.Header>
                                        <Row>
                                            <Col md="6">
                                                <Card.Title as="h4">
                                                    Invoice {data.invoice_nr} &nbsp;
                                                    {parseFloat(props.sale.order.paidAmount.toFixed(2)) == 0 && <span bg="danger" class="badge badge-danger">Un Paid</span>}
                                                    {parseFloat(props.sale.order.grandTotal.toFixed(2)) - parseFloat(props.sale.order.paidAmount.toFixed(2)) == 0 && <span bg="success" class="badge badge-success">Paid</span>}
                                                    {parseFloat(props.sale.order.paidAmount.toFixed(2)) > 0 && parseFloat(props.sale.order.grandTotal.toFixed(2)) > parseFloat(props.sale.order.paidAmount.toFixed(2)) && <span bg="warning" class="badge badge-warning">Partially Paid</span>}
                                                </Card.Title>
                                            </Col>
                                            <Col md="6">
                                                <ul className="list-unstyled mb-0 float-right">

                                                    <li className="d-inline-block align-top">
                                                        <a href={ENV.invoicePath + data.invoice_nr + ".pdf"} target="_blank" className=" pull-right" >
                                                            <Button className="btn-fill pull-right mt-3" variant="info">
                                                                Download Invoice
                                                            </Button>&nbsp;
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Customer<span className="text-danger"> *</span></label>
                                                    <p>{data.shipping.name}</p>
                                                </Form.Group>

                                            </Col>

                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Email / Mobile<span className="text-danger"> *</span></label>
                                                    <p>{data.shipping.email} / {data.shipping.mobile}</p>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Card.Title as="h4" className="mb-2">System Products</Card.Title>
                                        <Row>
                                            <Col md="12">
                                                <Form.Group>
                                                    <div id="productsDiv">
                                                        <Row>
                                                            <Table bordered size="sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ "width": "40%" }}>Product</th>
                                                                        <th style={{ "width": "10%" }}>Quantity</th>
                                                                        <th style={{ "width": "15%" }}>Unit Price</th>
                                                                        <th style={{ "width": "15%" }}>Sub Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        data.systemProducts.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{item.item}</td>
                                                                                    <td>{item.quantity}</td>
                                                                                    <td>{currencyFormat(item.price)}</td>
                                                                                    <td>{currencyFormat(item.subTotal)}</td>
                                                                                </tr>
                                                                            )
                                                                        })

                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Row>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Card.Title as="h4" className="mb-2">Non System Products</Card.Title>
                                            <Col md="12">
                                                <Form.Group>
                                                    <div id="productsDiv">
                                                        <Row>
                                                            <Table bordered size="sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ "width": "15%" }}>Product</th>
                                                                        <th style={{ "width": "35%" }}>Design</th>
                                                                        <th style={{ "width": "10%" }}>Body Part</th>
                                                                        <th style={{ "width": "10%" }}>Color</th>
                                                                        <th style={{ "width": "10%" }}>Price</th>
                                                                        <th style={{ "width": "10%" }}>Quantity</th>
                                                                        <th style={{ "width": "10%" }}>Sub Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        data.nonSystemProducts.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>

                                                                                        <p>{item.item}</p>
                                                                                        {item.subTitle &&

                                                                                            <span>Prompt:{item.subTitle}</span>
                                                                                        }

                                                                                    </td>
                                                                                    <td>
                                                                                        <Col md="12">
                                                                                            <Form.Group>
                                                                                                <div id="productsDiv">
                                                                                                    <Row>
                                                                                                        <Table bordered size="sm">
                                                                                                            <thead>
                                                                                                                <tr>

                                                                                                                    <th style={{ "width": "5%" }}>Design</th>
                                                                                                                    <th style={{ "width": "3%" }}>Quantity</th>
                                                                                                                    <th style={{ "width": "10%" }}>Unit Price</th>

                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>

                                                                                                                {item.innerDesign.map((design, index) => {
                                                                                                                    return (
                                                                                                                        <tr>
                                                                                                                            <td>  <img style={{ height: "50px", width: "50px" }} src={design.image ? design.image : imagePlaceholder} /></td>
                                                                                                                            <td>{design.quantity} </td>
                                                                                                                            <td>  {currencyFormat(design.price)}  </td>
                                                                                                                        </tr>

                                                                                                                    )
                                                                                                                })}
                                                                                                            </tbody>
                                                                                                        </Table>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                    </td>

                                                                                    <td>{getMatchValue(item.bodyPart, bodyParts)} </td>
                                                                                    <td>{getMatchValue(item.color, color)} </td>
                                                                                    <td>{item.price}</td>
                                                                                    <td>{item.quantity}</td>
                                                                                    <td>{currencyFormat(item.subTotal)}</td>

                                                                                </tr>
                                                                            )
                                                                        })

                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Row>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6" sm="6"></Col>
                                            <Col md="6" sm="6">
                                                <Table bordered>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Sub Total
                                                            </td>
                                                            <td>
                                                                {currencyFormat(data.subtotal)}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                Discount Total
                                                            </td>
                                                            <td>
                                                                {currencyFormat(data.discountTotal)}
                                                            </td>
                                                        </tr>

                                                        {data.taxtTotal > 0 ?
                                                            <tr>
                                                                <td>
                                                                    VAT ({data.vatPercentage} %)
                                                                </td>
                                                                <td>
                                                                    {currencyFormat(data.taxtTotal)}
                                                                </td>
                                                            </tr>
                                                            : ''}
                                                        <tr>
                                                            <td>
                                                                Grand Total
                                                            </td>
                                                            <td>
                                                                {currencyFormat(data.grandTotal)}
                                                            </td>
                                                        </tr>
                                                        {data.paidAmount > 0 ?
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        Amount Paid
                                                                    </td>
                                                                    <td>
                                                                        {currencyFormat(data.paidAmount)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Remaining Amount
                                                                    </td>
                                                                    <td>
                                                                        {currencyFormat(data.grandTotal - data.paidAmount)}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            : ""}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" sm="6">
                                                <Link to={'/edit-sale/' + orderId} className=" pull-right" >
                                                    <Button className="btn-fill pull-right mt-3" variant="info">
                                                        Back
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Container >
            }
        </>
    )
}
const mapStateToProps = state => ({
    sale: state.sale,
    error: state.error,

});

export default connect(mapStateToProps, { beforeSale, getInvoice, registerPayment })(Invoice);