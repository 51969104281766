import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { beforePromotion, getPromotion, addPromotion, updatePromotion } from './Promotions.action';
import { getProducts } from '../Products/Products.action';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import 'rc-pagination/assets/index.css';
import { Button, Card, Form, Table, Container, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import { getCategories } from '../Products/Category/Category.action';
import { Link, useHistory } from 'react-router-dom'
import { getRole } from 'views/AdminStaff/permissions/permissions.actions';
import { beforeCustomer, getCustomers } from '../Customers/Customers.action'
import { ENV } from '../../config/config';
import validator from 'validator';
var CryptoJS = require("crypto-js");

import PromotionRuleTableRow from './PromotionRuleTableRow';

const PromotionForm = (props) => {

    const [promotion, setPromotion] = useState({
        name: '',
        customers: [],
        isActive: true,
        promotionCode: '',
        rules:[],
    })
    const promotionID = window.location.pathname.split('/')[3]
    const [promotionRules, setPromotionRules] = useState([])
    const [customerOptions, setCustomerOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [msg, setMsg] = useState({
        name: '',
        promotionCode: '',
        rules: '',
    })
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        window.scroll(0, 0)
        const filter = { status: "true" }
        const qs = ENV.objectToQueryString({ all: 1, withProducts: 1 })
        
        if(window.location.pathname.split('/')[3]){
            props.getPromotion(window.location.pathname.split('/')[3])
        }

        props.getCustomers(qs)
        props.getCategories(qs)

        let roleEncrypted = localStorage.getItem('role');
        let role = ''
        if (roleEncrypted) {
            let roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, 'secret key 123').toString(CryptoJS.enc.Utf8);
            role = roleDecrypted
        }
        props.getRole(role)
    }, [])

    useEffect(() => {
        if (props.customer.getCustomerAuth) {
            let { customers } = props.customer
            let customers_ = []
            customers.map(customer => {
                customers_.push({
                    label: customer.customername+" / "+customer.email,
                    value: customer._id
                })
            })
            setCustomerOptions(customers_)
            setLoader(false)
            props.beforeCustomer()
        }
    }, [props.customer.getCustomerAuth])            // Get Customer List Fetched

    useEffect(() => {
        if (props.category.getCategoriesAuth) {
            let { categories, pagination } = props.category.categories
            let categories_ = []
            let products_ = []
            categories.map(category => {
                products_ = []
                category.products.map((product) => {
                    products_.push({
                        label: product.title,
                        value: product._id
                    })
                })
                categories_.push({
                    label: category.name,
                    value: category._id,
                    products: products_
                })
            })
            setCategoryOptions(categories_)
        }
    }, [props.category.getCategoriesAuth])          // Get Categories List Fetched


    useEffect(() => {
        if(props.promotion.getPromotionAuth){
            let promo = props.promotion.promotion
            setPromotion({
                name: promo.name,
                customers: promo.customers,
                isActive: promo.isActive,
                promotionCode: promo.promotionCode,
            })
            setPromotionRules(promo.rules)
            
        }
    }, [props.promotion.getPromotionAuth])                // Promotion Fetched

    useEffect(() => {
        if(props.promotion.createAuth || props.promotioneditPromotionAuth){
            props.history.push(`/promotions`)
        }
    }, [props.promotion.createAuth, props.promotioneditPromotionAuth ])                // Promotion Saved

 
    
    const setSelectedCustomer = (event) => {
        if (event) {
            const arrayOfIds = event.map((obj) => obj.value);
            setPromotion({...promotion, customers:arrayOfIds})
        } else {
            setPromotion({...promotion, customers:[]})
        }
    }
    const addRule = () => {
        setPromotionRules([
            ...promotionRules,
            {
                categoryId: null,
                productId: null,
                
                minQty: '',
                
                startDate: '',
                endDate: '',
                
                type: 0,
                discountAmount: '',
                discountType: '',  //1=Fixed 0=percentage
            }
        ])
    }
    const deleteRule = (index) => {
        let localRules = promotionRules
        localRules.splice(index, 1); // 2nd parameter means remove one item only
        setPromotionRules([...localRules])
    }
    const updateRule = (rule_, index) => {
        let localRules = promotionRules
        localRules[index] = rule_
        setPromotionRules([...localRules])
    }

    const submit = () => {
        let isValid = true
        if(!promotion.name || !promotion.promotionCode || promotionRules.length == 0){
            isValid = false
           
            setMsg({
                ...msg,
                name: validator.isEmpty(promotion.name) ? "Promotion Name is required." : '',
                promotionCode: validator.isEmpty(promotion.promotionCode) ? 'Promotion Code is required.' : '',
                rules: promotionRules.length == 0 ? "Atleast one rule is required" : null 
            })
        }
        if(promotionRules.length > 0){
            for(let i = 0; i < promotionRules.length; i++){
                if(promotionRules[i].minQty == 0 || promotionRules[i].minQty == '' || 
                promotionRules[i].discountAmount == 0 || promotionRules[i].discountAmount == ''||
                promotionRules[i].startDate == '' || promotionRules[i].endDate == '' ||
                !promotionRules[i].type ||
                ( promotionRules[i].type == 2 && !promotionRules[i].categoryId)   ||
                ( promotionRules[i].type == 3 && ( !promotionRules[i].categoryId || !promotionRules[i].productId) )   
                ){
                    isValid = false
                    setMsg({
                        ...msg,
                        rules: "One of the rules entered is inValid." 
                    })
                }
            }
        }
        if(isValid){
            
            if(window.location.pathname.split('/')[3]){
                let payload = {
                    _id: window.location.pathname.split('/')[3],
                    name: promotion.name,
                    customers:  promotion.customers.length > 0 ? JSON.stringify(promotion.customers) : null,
                    isActive: promotion.isActive,
                    promotionCode: promotion.promotionCode,
                    rules: JSON.stringify(promotionRules),
                }
                props.updatePromotion(payload)
            }else{
                let payload = {
                    name: promotion.name,
                    customers:  promotion.customers.length > 0 ? JSON.stringify(promotion.customers) : null,
                    isActive: promotion.isActive,
                    promotionCode: promotion.promotionCode,
                    rules: JSON.stringify(promotionRules),
                }
                props.addPromotion(payload)
            }
        }
    }

    return (
        <>
            {
                loader && !promotion?.name ?
                    <FullPageLoader />
                    :
                    <Container>
                        <Row>
                            <Col md="12">
                                <Card className="pb-3 table-big-boy">
                                    <Card.Header>
                                        <Card.Title as="h4">{window.location.pathname.split('/')[3] ? "Edit" : "Add"} Promotion</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>

                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Customer</label>
                                                    <Select options={customerOptions ? customerOptions : ''}
                                                        isMulti
                                                        className="basic-multi-select"
                                                        onChange={setSelectedCustomer}

                                                        value={customerOptions.length>0 && customerOptions?.filter(option => promotion.customers?.includes(option.value))}
                                                    />
                                                    <span className={msg.customer ? `` : `d-none`}>
                                                        <label className="pl-1 text-danger">{msg.customer}</label>
                                                    </span>
                                                </Form.Group>

                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Promotion Name<span className="text-danger"> *</span></label>
                                                    <Form.Control
                                                        value={promotion?.name ? promotion?.name : ''}
                                                        onChange={(e) => {
                                                            setPromotion({ ...promotion, name: e.target.value });
                                                        }}
                                                        placeholder="Title"
                                                        type="text"
                                                        onInput={() => setMsg((prevMsg) => ({ ...prevMsg, name: '' }))}
                                                    ></Form.Control>
                                                    <span className={msg.name ? `` : `d-none`}>
                                                        <label className="pl-1 text-danger">{msg.name}</label>
                                                    </span>
                                                </Form.Group>
                                            </Col>

                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Promotion Code<span className="text-danger"> *</span></label>
                                                    <Form.Control
                                                        value={promotion?.promotionCode ? promotion?.promotionCode : ''}
                                                        onChange={(e) => {
                                                            setPromotion({ ...promotion, promotionCode: e.target.value });
                                                        }}
                                                        placeholder="Title"
                                                        type="text"
                                                        onInput={() => setMsg((prevMsg) => ({ ...prevMsg, promotionCode: '' }))}
                                                    ></Form.Control>
                                                    <span className={msg.promotionCode ? `` : `d-none`}>
                                                        <label className="pl-1 text-danger">{msg.promotionCode}</label>
                                                    </span>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12">
                                                <Form.Group>
                                                    
                                                    <Card.Title as="h4">Promotion Rules</Card.Title>
                                                    <div id="attributeValuesDiv">
                                                        <Table bordered size="sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Min Qty</th>
                                                                    <th>Date Range</th>
                                                                    <th>Discount Percentage</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    promotionRules.length ?
                                                                        promotionRules.map((rule, index) => {
                                                                        
                                                                            return (
                                                                                <PromotionRuleTableRow
                                                                                    index={index}
                                                                                    categories={categoryOptions}
                                                                                    products={[]}
                                                                                    updateRule={updateRule}
                                                                                    deleteRule={deleteRule}
                                                                                    rule={rule}

                                                                                ></PromotionRuleTableRow>
                                                                            )
                                                                        })
                                                                        :
                                                                        <tr>
                                                                            <td colSpan="5" className="text-center">
                                                                                <div className="alert alert-info cursor-button" role="alert" onClick={addRule}>Click here to add first rule </div>
                                                                            </td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <span className={msg.rules ? `` : `d-none`}>
                                                        <label className="pl-1 text-danger">{msg.rules}</label>
                                                    </span>
                                                </Form.Group>
                                                
                                                <Button className="btn-fill" variant="info" onClick={addRule}>
                                                    Add New Rule
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label className='mr-2'>Status<span className="text-danger"> *</span></label>
                                                    <label className="right-label-radio mb-2 mr-2">
                                                        <div className='d-flex align-items-center'>
                                                            <input name="isActive" type="radio" checked={promotion?.isActive} value={promotion?.isActive} onChange={(e) => { setPromotion({ ...promotion, isActive: true }) }} />
                                                            <span className="checkmark black-checkmark"></span>
                                                            <span className='ml-1' onChange={(e) => {
                                                                setPromotion({ ...promotion, isActive: true });
                                                            }} ><i />Active</span>
                                                        </div>
                                                    </label>
                                                    <label className="right-label-radio mr-3 mb-2">
                                                        <div className='d-flex align-items-center'>
                                                            <input name="isActive" type="radio" checked={!promotion?.isActive} value={!promotion?.isActive} onChange={(e) => { setPromotion({ ...promotion, isActive: false }) }} />
                                                            <span className="checkmark black-checkmark"></span>
                                                            <span className='ml-1' onChange={(e) => {
                                                                setPromotion({ ...promotion, isActive: false });
                                                            }} ><i />Inactive</span>
                                                        </div>
                                                    </label>

                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" sm="6">
                                                <Link to={'/promotions'} className=" pull-right" >
                                                    <Button className="btn-fill pull-right mt-3" variant="info">
                                                        Back
                                                    </Button>
                                                </Link>
                                                <Button
                                                    className="btn-fill float-right mt-3"
                                                    type="submit"
                                                    variant="info"
                                                    onClick={submit}
                                                >
                                                    {window.location.pathname.split('/')[3] ? "Update" : "Add"}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
            }
        </>
    )
}

const mapStateToProps = state => ({
    category: state.category,
    customer: state.customer,
    promotion: state.promotion,
    error: state.error,
    getRoleRes: state.role.getRoleRes,
    productAttribute: state.productAttribute
});

export default connect(mapStateToProps, { beforeCustomer, getCustomers, beforePromotion, getPromotion, addPromotion, updatePromotion, getProducts, getRole, getCategories })(PromotionForm);