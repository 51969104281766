import { BEFORE_CATEGORY,CREATE_CATEGORY,GET_CATEGORY , GET_CATEGORIES, EDIT_CATEGORY, DELETE_CATEGORY, } from '../../../redux/types';

const initialState = {
    categories: null,
    getCategoriesAuth: false,
    category: null,
    delCategoryAuth: false,
    createAuth: false,
    getCategoriesAuth: false,
    editCategoryAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
       
        case CREATE_CATEGORY:
            return {
                ...state,
                createAuth: true
            }
        case GET_CATEGORIES:
            console.log("🚀 ~ file: Category.reducer.js:20 ~ action:", action)
            return {
                ...state,
                categories: action.payload,
                getCategoriesAuth: true
            }
        case EDIT_CATEGORY:
            return {
                ...state,
                category: action.payload,
                editCategoryAuth: true
            }
        case DELETE_CATEGORY:
            return {
                ...state,
                category: action.payload,
                delCategoryAuth: true
            }
        case BEFORE_CATEGORY:
            return {
                ...state,
                categories: null,
                getCategoriesAuth: false,
                category: null,
                delCategoryAuth: false,
                createAuth: false,
                getCategoriesAuth: false,
                editCategoryAuth: false
            }
        default:
            return {
                ...state
            }
    }
}