import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div className="content">
        404 - PAGE NOT FOUND
      </div>
    );
  }
}

export default NotFound;