import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addSizeGroup, beforeSizeGroup } from './SizeGroup.action';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import 'rc-pagination/assets/index.css';
import { Button, Card, Form, Table, Container, Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import validator from 'validator';
import { Link } from 'react-router-dom'
import TinyMCE from '../../components/tinyMce/tinyMCE'

const SizeGroupForm = (props) => {

    const [data, setData] = useState({
        title: '',
        display_order: '',
        desc: '',
        status: true
    })

    const [msg, setMsg] = useState({
        title: '',
        display_order: '',
        desc: '',
    })

    const [loader, setLoader] = useState(true)

    useEffect(() => {
        window.scroll(0, 0)
        setLoader(false)
    }, [])

    useEffect(() =>{
        if(props.sizeGroups.createAuth){
            props.beforeSizeGroup()
            setLoader(false)
            props.history.push(`/sizeGroup`)
        }
    }, [props.sizeGroups.createAuth])

    const add = () => {
        if (!validator.isEmpty(data.title) && !validator.isEmpty(data.display_order) && !validator.isEmpty(data.desc)) {
            setMsg({
                title: '',
                display_order: '',
                desc: '',
            })

            setLoader(true)
            let formData = new FormData()
            for (const key in data)
                formData.append(key, data[key])
            props.addSizeGroup(formData)
        }
        else {
            let title = ''
            let display_order = ''
            let desc = ''
            let titleDE = ''
            let descDE = ''
            if (validator.isEmpty(data.title)) {
                title = 'Title in english required.'
            }
            if (validator.isEmpty(data.display_order)) {
                display_order = 'Display order required.'
            }
            if (validator.isEmpty(data.desc)) {
                desc = 'Description in english required.'
            }
            setMsg({ title, display_order, desc, titleDE, descDE })
        }
    }


    return (
        <>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <Container>
                        <Row>
                            <Col md="12">
                                <Card className="pb-3 table-big-boy">
                                    <Card.Header>
                                        <Card.Title as="h4">Add SIZE_GROUP</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md="8">
                                                <Form.Group>
                                                    <label>Question<span className="text-danger"> *</span></label>
                                                    <Form.Control
                                                        value={data.title ? data.title : ''}
                                                        onChange={(e) => {
                                                            setData({ ...data, title: e.target.value });
                                                        }}
                                                        placeholder="Title"
                                                        type="text"
                                                    ></Form.Control>
                                                    <span className={msg.title ? `` : `d-none`}>
                                                        <label className="pl-1 text-danger">{msg.title}</label>
                                                    </span>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Display Order<span className="text-danger"> *</span></label>
                                                    <Form.Control
                                                        value={data.display_order ? data.display_order : ''}
                                                        onChange={(e) => {
                                                            setData({ ...data, display_order: e.target.value });
                                                        }}
                                                        placeholder="Display Order"
                                                        type="number"
                                                    ></Form.Control>
                                                    <span className={msg.display_order ? `` : `d-none`}>
                                                        <label className="pl-1 text-danger">{msg.display_order}</label>
                                                    </span>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" sm="6">
                                                <label>Answer / Description<span className="text-danger"> *</span></label>
                                                <Form.Control
                                                        as="textarea" rows={3}
                                                        value={data.desc ?? ''}
                                                        onChange={(e) => {
                                                            setData({ ...data, desc: e.target.value });
                                                        }}
                                                        placeholder="Title"
                                                        type="text"
                                                ></Form.Control>
                                                <span className={msg.desc ? `` : `d-none`}>
                                                    <label className="pl-1 text-danger">{msg.desc}</label>
                                                </span>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label className='mr-2'>Status<span className="text-danger"> *</span></label>
                                                    <label className="right-label-radio mb-2 mr-2">
                                                        <div className='d-flex align-items-center'>
                                                        <input name="status" type="radio" checked={data.status} value={data.status} onChange={(e) => {setData({ ...data, status: true })}} />
                                                        <span className="checkmark"></span>
                                                        <span className='ml-1' onChange={(e) => {
                                                            setData({ ...data, status: true });
                                                        }} ><i />Active</span>
                                                        </div>
                                                    </label>
                                                    <label className="right-label-radio mr-3 mb-2">
                                                        <div className='d-flex align-items-center'>
                                                        <input name="status" type="radio" checked={!data.status} value={!data.status} onChange={(e) => {setData({ ...data, status: false })}}  />
                                                        <span className="checkmark"></span>
                                                        <span className='ml-1' onChange={(e) => {
                                                            setData({ ...data, status: false });
                                                        }} ><i />Inactive</span>
                                                        </div>
                                                    </label>
                                                    
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" sm="6">
                                                <Button
                                                    className="btn-fill pull-right mt-3 float-right"
                                                    type="submit"
                                                    variant="info"
                                                    onClick={add}
                                                >
                                                    Add
                                                </Button>
                                                <Link to={'/sizeGroup'} >
                                                    <Button className="btn-fill pull-right mt-3" variant="info">
                                                     Back
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
            }
        </>
    )
}

const mapStateToProps = state => ({
    sizeGroups: state.sizeGroups,
    error: state.error
});

export default connect(mapStateToProps, { addSizeGroup, beforeSizeGroup })(SizeGroupForm);