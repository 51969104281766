import { toast } from 'react-toastify';
import { GET_ERRORS, CREATE_CATEGORY, BEFORE_CATEGORY, GET_CATEGORIES, EDIT_CATEGORY, DELETE_CATEGORY} from '../../../redux/types';
import { emptyError } from '../../../redux/shared/error/error.action';
import { ENV } from './../../../config/config';

export const beforeCategory = () => {
    return {
        type: BEFORE_CATEGORY
    }
}

export const getCategories = (qs = '', body ={}) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}category/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        console.log("🚀 ~ file: Category.action.js:29 ~ getCategories ~ data:", data)
        if (data.success) {
            console.log("🚀 ~ file: Category.action.js:29 ~ getCategories ~ data.success:", data.success)
            if (!qs)
                toast.success(data.message)
            dispatch({
                type: GET_CATEGORIES,
                payload: data.data
            })
        } else {
            if (!qs)
                toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const updateCategory = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}category/edit`;

    fetch(url, {
        method: 'PUT',
        headers: {
            'Authorization': ENV.Authorization,
            'Content-Type': "application/json",
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: EDIT_CATEGORY,
                payload: data
            })
        } else {
            if(data.exist){
                toast.error(data.message)
                dispatch({
                    type: EDIT_CATEGORY,
                    payload: data
                })
            }
            else{
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }}
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const deleteCategory = (categoryId) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}category/delete/${categoryId}`;

    fetch(url, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: DELETE_CATEGORY,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const addCategory = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}category/create`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'Content-Type': "application/json",
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: CREATE_CATEGORY,
                payload: data
            })
        } else {
            if(data.exist){
                toast.error(data.message)
                dispatch({
                    type: CREATE_CATEGORY,
                    payload: data
                })
            }else{
                
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
