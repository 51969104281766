import { useState, useEffect, useRef } from "react";
import { ENV } from '../../config/config';
import { getSettings, beforeSettings, editSettings } from './settings.action';
import { connect } from 'react-redux';
import { getRole } from "views/AdminStaff/permissions/permissions.actions";
import FullPageLoader from "components/FullPageLoader/FullPageLoader";
var CryptoJS = require("crypto-js");
import validator from 'validator';
import userDefaultImg from '../../assets/img/default-user-icon-13.jpg';

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";


const SiteSettings = (props) => {

	const myRef = useRef(null)

	const [permissions, setPermissions] = useState({})
	const [loader, setLoader] = useState(true)
	const [siteLogo, setSiteLogo] = useState('')
	const [errorMessage, setErrorMessage] = useState(false)
	const [splashScreen, setSplashScreen] = useState([])
	const [msg, setMsg] = useState({
		splashScreen: '',
		inquiryEmail: '',
		email: '',
		phone: '',
		mobile: '',
		address: '',
		vatPercentage: '',
		orderEmailRecipients: '',
		registrationEmailRecipients: '',
		pinterest: '',
		facebook: '',
		twitter: '',
		linkedin: '',

	})

	useEffect(() => {
		window.scroll(0, 0)
		const callback = () => {
			setLoader(false);
		}
		props.getSettings(callback)
		let roleEncrypted = localStorage.getItem('role');
		let role = ''
		if (roleEncrypted) {
			let roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, 'secret key 123').toString(CryptoJS.enc.Utf8);
			role = roleDecrypted
		}
		props.getRole(role)
	}, [])

	useEffect(() => {
		if (Object.keys(props.getRoleRes).length > 0) {
			setPermissions(props.getRoleRes.role)
		}
	}, [props.getRoleRes])

	useEffect(() => {
		if (props.settings.settingsAuth) {
			if (props.settings.settings) {
				setLoader(false)
				const settingsData = props.settings.settings
				setSettings({ 
					inquiryEmail: settingsData?.inquiryEmail,
					email: settingsData?.email,
					phone: settingsData?.phone,
					mobile: settingsData?.mobile,
					address: settingsData?.address,
					vatPercentage: settingsData?.vatPercentage,
					orderEmailRecipients: settingsData?.orderEmailRecipients,
					registrationEmailRecipients: settingsData?.registrationEmailRecipients,
					pinterest: settingsData?.pinterest,
					facebook: settingsData?.facebook,
					twitter: settingsData?.twitter,
					linkedin: settingsData?.linkedin,
				 })
				 console.log("🚀 ~ file: SiteSettings.js:82 ~ useEffect ~ settingsData:", settingsData)
				setSplashScreen(arr => [...settingsData.splashScreen])
				if (siteLogo === '') {
					setSiteLogo(settingsData.siteLogo)
				}
			}
			props.beforeSettings()
		}
	}, [props.settings.settingsAuth])

	const [settings, setSettings] = useState({
		inquiryEmail: '',
		email: '',
		phone: '',
		mobile: '',
		address: '',
		vatPercentage: '',
		orderEmailRecipients: '',
		registrationEmailRecipients: '',
		pinterest: '',
		facebook: '',
		twitter: '',
		linkedin: ''
	})

	const submit = () => {
		let check = true
		let emailcheck = true
		let emailcheck1 = true
		let emailcheck2 = true

		setMsg({
			splashScreen: splashScreen.length < 1 ? 'Splash Data is required' : '',
			inquiryEmail: validator.isEmpty(settings.inquiryEmail) ? 'Inquiry Email is required' : '',
			email: validator.isEmpty(settings.email) ? 'Email is required' : !validator.isEmpty(settings.email) && !validator.isEmail(settings.email) ? 'Please input valid email' : '',
			phone: validator.isEmpty(settings.phone) ? 'Phone is required' : '',
			mobile: validator.isEmpty(settings.mobile) ? 'Mobile is required' : '',
			address: validator.isEmpty(settings.address) ? 'Address is required' : '',
			vatPercentage: validator.isEmpty(settings.vatPercentage) ? 'Vat Percentage is required' : !validator.isEmpty(settings.vatPercentage) && !validator.isFloat(settings.vatPercentage) ? 'Please input correct format.' : '',

			orderEmailRecipients: validator.isEmpty(settings.orderEmailRecipients) ? 'Order Email Recipients is required' : '',
			registrationEmailRecipients: validator.isEmpty(settings.registrationEmailRecipients) ? 'Registration Email Recipients is required' : '',

			pinterest: !validator.isEmpty(settings.pinterest) && !validator.isURL(settings.pinterest) ? 'Invalid URL' : '',
			facebook: !validator.isEmpty(settings.facebook) && !validator.isURL(settings.facebook) ? 'Invalid URL' : '',
			twitter: !validator.isEmpty(settings.twitter) && !validator.isURL(settings.twitter) ? 'Invalid URL' : '',
			linkedin: !validator.isEmpty(settings.linkedin) && !validator.isURL(settings.linkedin) ? 'Invalid URL' : '',
		})

		const match = settings.orderEmailRecipients.split(',')

		for (var a in match) {
			var variable = match[a]
			if (!validator.isEmail(variable)) {
				setMsg({ orderEmailRecipients: !validator.isEmail(variable) ? 'Please input valid email' : '' })
				emailcheck = false
				myRef.current.scrollIntoView()
				setErrorMessage(true)
				return;
			}
		}

		const match1 = settings.registrationEmailRecipients.split(',')

		for (var a in match1) {
			var variable = match1[a]
			if (!validator.isEmail(variable)) {
				setMsg({ registrationEmailRecipients: !validator.isEmail(variable) ? 'Please input valid email' : '' })
				emailcheck1 = false
				myRef.current.scrollIntoView()
				setErrorMessage(true)
				return;
			}
		}

		// const match2 = settings.inquiryEmail.split(',')

		// for (var a in match2) {
		// 	var variable = match2[a]
		// 	if (!validator.isEmail(variable)) {
		// 		setMsg({ inquiryEmail: !validator.isEmail(variable) ? 'Please input valid email' : '' })
		// 		emailcheck2 = false
		// 		myRef.current.scrollIntoView()
		// 		setErrorMessage(true)
		// 		return;
		// 	}
		// }


		if (validator.isEmpty(settings.inquiryEmail)

			|| (validator.isEmpty(settings.phone))
			|| (validator.isEmpty(settings.mobile))
			
			|| validator.isEmpty(settings.address)
			|| validator.isEmpty(settings.vatPercentage)
			|| validator.isEmpty(settings.orderEmailRecipients)
			|| validator.isEmpty(settings.registrationEmailRecipients)
			|| (!validator.isEmpty(settings.email) && !validator.isEmail(settings.email))
			
			|| splashScreen.length < 1

			|| (!validator.isEmpty(settings.pinterest) && !validator.isURL(settings.pinterest))
			|| (!validator.isEmpty(settings.facebook) && !validator.isURL(settings.facebook))
			|| (!validator.isEmpty(settings.twitter) && !validator.isURL(settings.twitter))
			|| (!validator.isEmpty(settings.linkedin) && !validator.isURL(settings.linkedin))
		) {
			check = false
			setErrorMessage(true)
			myRef.current.scrollIntoView()
		}

		if (check && emailcheck && emailcheck1 && emailcheck2) {
			let payload ={
				splashScreen: splashScreen,
				inquiryEmail: settings.inquiryEmail,
				email: settings.email,
				phone: settings.phone,
				mobile: settings.mobile,
				address: settings.address,
				vatPercentage: settings.vatPercentage,
				orderEmailRecipients: settings.orderEmailRecipients,
				registrationEmailRecipients: settings.registrationEmailRecipients,
				pinterest: settings.pinterest,
				facebook: settings.facebook,
				twitter: settings.twitter,
				linkedin: settings.linkedin,
			}

			const qs = ENV.objectToQueryString({ type: '1' })
			props.editSettings(payload, qs)
			setLoader(true)
			setErrorMessage(false)
		}
		else {
			setErrorMessage(true)
			myRef.current.scrollIntoView()
		}
	}

	const addSplash = () => {
		setSplashScreen( arr => [...arr, {
			text: "",
			image: ""
		}]);
	}
	const removeSplash = (index) => {
		let splashScreen_ = splashScreen
		splashScreen_.splice(index,1) 
		setSplashScreen( arr => [...splashScreen_]);
	}
	const updateSplash = (data, type, index) => {
		let splashScreen_ = splashScreen
		splashScreen_[index][type] = data
		setSplashScreen( arr => [...splashScreen_]);
	}

	return (
		<>
			{
				loader ? <FullPageLoader /> :
					<Container fluid>
						<Row ref={myRef}>
							<Col md="12">
								<Form action="" className="form-horizontal settings-form" id="TypeValidation" method="">
									<Card className="table-big-boy">
										<Card.Header>
											<div className="d-block d-md-flex align-items-center justify-content-between">
												<Card.Title as="h4">Site Settings</Card.Title>
											</div>

											<span className={errorMessage ? `` : `d-none`}>
												<label className="pl-1 pt-0 text-danger">Missing required fields</label>
											</span>

										</Card.Header>

										<Card.Body>
											<Row>
												<Col sm={12}>
													<p className="mb-4">
														<strong>Mobile OnBorading Screen Text</strong> 
														<span
															className="btn-action btn-success"
															type="button"
															variant="success" title="Add Splash Text"
															onClick={() => addSplash()}
														>
														<i className="fas fa-plus"></i>
														</span>
													</p>
												</Col>
												<Col sm={12}>
													<Form.Group>
														<table style={{ width:"100%" }}>
														{
															splashScreen.length > 0 ? splashScreen.map((splash, index) => {
																return (
																		<tr key={index}>
																			<td style={{ width:"70%" }}>
																				<Form.Control type="email" value={splash.text} onChange={(e) => { updateSplash(e.target.value, 'text', index) }}></Form.Control>
																			</td>
																			<td style={{ width: "20%" }}>
																				<Form.Group className="text-center">
																					<div className='mb-2'>
																						{/* {<img className="img-thumbnail" src={profileImage ? ENV.uploadedImgPath+profileImage : userDefaultImg} onError={(e) => { e.target.onerror = null; e.target.src = userDefaultImg }} style={{ width: '100px' }} />} */}
																						{<img className="img-thumbnail" src={splash.image ? ENV.uploadedImgPath+splash.image : userDefaultImg} onError={(e) => { e.target.onerror = null; e.target.src = userDefaultImg }} style={{ width: '100px' }} />}
																					</div>
																					<Form.Control
																						className='text-white'
																						onChange={async (e) => {
																							const res = await ENV.uploadImage(e);
																							updateSplash(res ? res : '', 'image', index)
																						}}
																						accept="image/*"
																						type="file"
																					></Form.Control>
																				</Form.Group>
																			</td>
																			<td style={{ width:"30%" }}>
																				<ul className="d-inline-block list-unstyled mb-0">
																					{ splashScreen.length > 1 && 
																						<li className="d-inline-block align-top">
																								<Button
																									className="btn-action btn-danger"
																									type="button"
																									variant="danger" title="Delete onBoarding Text"
																									onClick={() => removeSplash(index)}
																								>
																								<i className="fas fa-trash"></i>
																								</Button>
																						</li>
																						}
																						{(splashScreen.length-1) == index &&
																						<li className="d-inline-block align-top">
																								<Button
																									className="btn-action btn-success"
																									type="button"
																									variant="success" title="Add onBoarding Text"
																									onClick={() => addSplash()}
																								>
																								<i className="fas fa-plus"></i>
																								</Button>
																						</li>
																						}
																				</ul>
																			</td>
																		</tr>
																)
															})
															:
															<Form.Label className="d-block mb-2">Kindly add onBoarding screen text </Form.Label>
														}
														</table>
													</Form.Group>
													<span className={msg.splashScreen ? `` : `d-none`}>
														<label className="pl-1 pt-0 text-danger">{msg.splashScreen}</label>
													</span>
												</Col>
												<Col sm={12}>
													<p className="mb-4"><strong>Contact Information</strong></p>
												</Col>
												<Col sm={12}>
													<Row>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Inquiry Email<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="email" value={settings.inquiryEmail} onChange={(e) => { setSettings({ ...settings, inquiryEmail: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.inquiryEmail ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.inquiryEmail}</label>
															</span>
														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Email<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="email" value={settings.email} onChange={(e) => { setSettings({ ...settings, email: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.email ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.email}</label>
															</span>

														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Phone<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="tel" value={settings.phone} onChange={(e) => { setSettings({ ...settings, phone: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.phone ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.phone}</label>
															</span>

														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Mobile<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="tel" value={settings.mobile} onChange={(e) => { setSettings({ ...settings, mobile: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.mobile ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.mobile}</label>
															</span>

														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2"> Address<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="text" value={settings.address} onChange={(e) => { setSettings({ ...settings, address: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.address ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.address}</label>
															</span>
														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">VAT Percentage<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="number" value={settings.vatPercentage} onChange={(e) => { setSettings({ ...settings, vatPercentage: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.vatPercentage ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.vatPercentage}</label>
															</span>
														</Col>
													</Row>
												</Col>
											</Row>
											<hr />
											<Row>
												<Col sm={12}>
													<p className="mb-4"><strong>Social Media Information</strong></p>
												</Col>
												<Col xl={6} sm={6}>
													<Form.Group>
														<Form.Label className="d-block mb-2">Pinterest</Form.Label>
														<Form.Control type="url" value={settings.pinterest} onChange={(e) => { setSettings({ ...settings, pinterest: e.target.value }) }}></Form.Control>
													</Form.Group>
													<span className={msg.pinterest ? `` : `d-none`}>
														<label className="pl-1 pt-0 text-danger">{msg.pinterest}</label>
													</span>
												</Col>
												<Col xl={6} sm={6}>
													<Form.Group>
														<Form.Label className="d-block mb-2">Facebook</Form.Label>
														<Form.Control type="url" value={settings.facebook} onChange={(e) => { setSettings({ ...settings, facebook: e.target.value }) }}></Form.Control>
													</Form.Group>
													<span className={msg.facebook ? `` : `d-none`}>
														<label className="pl-1 pt-0 text-danger">{msg.facebook}</label>
													</span>
												</Col>
												<Col xl={6} sm={6}>
													<Form.Group>
														<Form.Label className="d-block mb-2">Twitter</Form.Label>
														<Form.Control type="url" value={settings.twitter} onChange={(e) => { setSettings({ ...settings, twitter: e.target.value }) }}></Form.Control>
													</Form.Group>
													<span className={msg.twitter ? `` : `d-none`}>
														<label className="pl-1 pt-0 text-danger">{msg.twitter}</label>
													</span>
												</Col>
												<Col xl={6} sm={6}>
													<Form.Group>
														<Form.Label className="d-block mb-2">Linkedin</Form.Label>
														<Form.Control type="url" value={settings.linkedin} onChange={(e) => { setSettings({ ...settings, linkedin: e.target.value }) }}></Form.Control>
													</Form.Group>
													<span className={msg.linkedin ? `` : `d-none`}>
														<label className="pl-1 pt-0 text-danger">{msg.linkedin}</label>
													</span>
												</Col>

											</Row>
											<hr />
											<Row>
												<Col sm={12}>
													<p className="mb-4"><strong>Email Settings</strong></p>
												</Col>
												<Col xl={6} sm={6}>
													<Form.Group>
														<Form.Label className="d-block mb-2">Order Email Recipients<span className="text-danger"> *</span></Form.Label>
														<Form.Control type="email" value={settings.orderEmailRecipients} onChange={(e) => { setSettings({ ...settings, orderEmailRecipients: e.target.value }) }}></Form.Control>
													</Form.Group>
													<span className={msg.orderEmailRecipients ? `` : `d-none`}>
														<label className="pl-1 pt-0 text-danger">{msg.orderEmailRecipients}</label>
													</span>
												</Col>
												<Col xl={6} sm={6}>
													<Form.Group>
														<Form.Label className="d-block mb-2">Registration Email Recipients<span className="text-danger"> *</span></Form.Label>
														<Form.Control type="email" value={settings.registrationEmailRecipients} onChange={(e) => { setSettings({ ...settings, registrationEmailRecipients: e.target.value }) }}></Form.Control>
													</Form.Group>
													<span className={msg.registrationEmailRecipients ? `` : `d-none`}>
														<label className="pl-1 pt-0 text-danger">{msg.registrationEmailRecipients}</label>
													</span>
												</Col>

											</Row>
										</Card.Body>


										<Card.Footer>
											<Row className="float-right">
												{
													permissions && permissions.editSetting &&
													<Col sm={12}>
														<Button variant="info" onClick={submit}>Save Settings</Button>
													</Col>
												}
											</Row>
										</Card.Footer>


									</Card>
								</Form>
							</Col>
						</Row>
					</Container>
			}
		</>
	);
}

const mapStateToProps = state => ({
	settings: state.settings,
	error: state.error,
	getRoleRes: state.role.getRoleRes

});

export default connect(mapStateToProps, { getSettings, beforeSettings, editSettings, getRole })(SiteSettings);
