import React, { useState, useEffect } from 'react';
import 'rc-pagination/assets/index.css';
import { Form, Button, Table } from "react-bootstrap";
import 'bootstrap-daterangepicker/daterangepicker.css';
import { ENV } from '../../config/config';

/**
 * 
 * @param { index, type, removeValue, addValue, updateValues, isLast  }
 * @returns 
 */
const ValueRow = (props) => {
    console.log(`Value ${props.index}  ${props.type}` )
    const [rowIndex, setRowIndex] = useState(props.index)
    const [type, setType] = useState(props.type)
    console.log(`1Value ${props.index}  ${type}` )
    
    const [value, setValue] = useState(props.value)
    
    const updateValue = (name, value_) => {
        setValue({
            ...value,
            [name]: value_
        })
    }
    useEffect(()=>{
        props.updateValues(value, rowIndex)
    },[value])
    const fileSelectHandler = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            updateValue('image',reader.result);
        };
        reader.readAsDataURL(files[0]);
    };
    return (
            <tr key={`value${rowIndex}`}>
                <td>
                    <Form.Control
                        style={{ "height":"37px" }}
                        onChange={(event) => { updateValue('title', event.target.value) }}
                        value={value.title}
                        type="text"
                    ></Form.Control>
                </td>
                <td>
                    {
                        props.type == 'isMeasurement' &&
                        <Form.Control
                            style={{ "height":"37px" }}
                            onChange={(event) => { updateValue('measurementScale', event.target.value) }}
                            value={value.measurementScale}
                            type="text"
                        ></Form.Control>
                    }
                    {
                        props.type == 'isColor' &&
                        <Form.Control
                            style={{ "height":"37px" }}
                            onChange={(event) => { updateValue('colorCode', event.target.value) }}
                            value={value.colorCode}
                            type="color"
                        ></Form.Control>
                    }
                    
                    {
                        props.type == 'isImage' &&
                        <>
                            { value.image && <img src={value.image} style={{ height: "30px", width: "30px" }}/> }
                            <Form.Group>
                                <Form.Control
                                    className='text-white'
                                    onChange={async (e) => {
                                        fileSelectHandler(e);
                                        const res = await ENV.uploadImage(e);
                                        updateValue('image', res ? ENV.uploadedImgPath + res : '');
                                    }}
                                    type="file"
                                ></Form.Control>
                            </Form.Group>
                        </>
                    }
                </td>
                <td>
                    <ul className="d-inline-block list-unstyled mb-0">
                        {props.count > 1 && 
                        <li className="d-inline-block align-top">
                                <Button
                                    className="btn-action btn-danger"
                                    type="button"
                                    variant="danger" title="Delete Value"
                                    onClick={() => props.removeValue(rowIndex)}
                                >
                                <i className="fas fa-trash"></i>
                                </Button>
                        </li>
                        }
                        {props.isLast &&
                        <li className="d-inline-block align-top">
                                <Button
                                    className="btn-action btn-success"
                                    type="button"
                                    variant="success" title="Add Value"
                                    onClick={() => props.addValue()}
                                >
                                <i className="fas fa-plus"></i>
                                </Button>
                        </li>
                        }
                    </ul>
                </td>
            </tr>
                   
    )
}

export default React.memo(ValueRow);