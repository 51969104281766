// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// ADMIN
export const ADD_ADMIN = 'ADD_ADMIN';
export const BEFORE_ADMIN = 'BEFORE_ADMIN';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMINS = 'GET_ADMINS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER_VERIFY = 'GET_USER_VERIFY';
export const BEFORE_USER_VERIFY = 'BEFORE_USER_VERIFY';
export const SET_PASSWORD = 'SET_PASSWORD';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SENT = 'VERIFY_EMAIL_SENT';


// ROLES
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const GET_ROLE = 'GET_ROLE';
export const EXISTS_ROLE = 'EXISTS_ROLE';
export const GET_ROLES = 'GET_ROLES';
export const BEFORE_ROLE = 'BEFORE_ROLE';
export const BEFORE_PERMISSION = 'BEFORE_PERMISSION';
export const GET_PERMISSION = 'GET_PERMISSION';
export const SET_LOADER_ROLE = 'SET_LOADER_ROLE';
export const REMOVE_LOADER_ROLE = 'REMOVE_LOADER_ROLE';

// USERS
export const BEFORE_CUSTOMER = 'BEFORE_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const UPSERT_CUSTOMER = 'UPSERT_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const CUSTOMER_VERIFICATION = 'CUSTOMER_VERIFICATION';

// Contact Query
export const BEFORE_CONTACTUSQUERY = 'BEFORE_CONTACTUSQUERY';
export const GET_CONTACTUSQUERIES = 'GET_CONTACTUSQUERIES';
export const GET_CONTACTUSQUERY = 'GET_CONTACTUSQUERY';
export const DELETE_CONTACTUSQUERY = 'DELETE_CONTACTUSQUERY';
export const UPSERT_CONTACTUSQUERY = 'UPSERT_CONTACTUSQUERY';

// Emails
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL = 'GET_EMAIL';
export const BEFORE_EMAIL = 'BEFORE_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';

// Settings
export const GET_SETTINGS = 'GET_SETTINGS';
export const EDIT_SETTINGS = 'EDIT_SETTINGS';
export const BEFORE_SETTINGS = 'BEFORE_SETTINGS';


// FAQS
export const GET_FAQS = 'GET_FAQS'
export const BEFORE_FAQ = 'BEFORE_FAQ'
export const DELETE_FAQ = 'DELETE_FAQ'
export const CREATE_FAQ = 'CREATE_FAQ'
export const GET_FAQ = 'GET_FAQ'
export const EDIT_FAQ = 'EDIT_FAQ'

// Cateogries
export const GET_FAQ_CATEGORIES = 'GET_FAQ_CATEGORIES'
export const BEFORE_FAQ_CATEGORY = 'BEFORE_FAQ_CATEGORY'
export const DELETE_FAQ_CATEGORY = 'DELETE_FAQ_CATEGORY'
export const CREATE_FAQ_CATEGORY = 'CREATE_FAQ_CATEGORY'
export const GET_FAQ_CATEGORY = 'GET_FAQ_CATEGORY'
export const EDIT_FAQ_CATEGORY = 'EDIT_FAQ_CATEGORY'

// Cateogries
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const BEFORE_CATEGORY = 'BEFORE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const GET_CATEGORY = 'GET_CATEGORY'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'


// Promotions
export const GET_PROMOTIONS= 'GET_PROMOTIONS'
export const BEFORE_PROMOTION = 'BEFORE_PROMOTION'
export const DELETE_PROMOTION = 'DELETE_PROMOTION'
export const CREATE_PROMOTION = 'CREATE_PROMOTION'
export const GET_PROMOTION = 'GET_PROMOTION'
export const EDIT_PROMOTION = 'EDIT_PROMOTION'
export const DOWNLOAD_PROMOTION_REPORT = 'DOWNLOAD_PROMOTION_REPORT'


// SIZEGROUPS
export const GET_SIZEGROUPS = 'GET_SIZEGROUPS'
export const BEFORE_SIZEGROUP = 'BEFORE_SIZEGROUP'
export const DELETE_SIZEGROUP = 'DELETE_SIZEGROUP'
export const CREATE_SIZEGROUP = 'CREATE_SIZEGROUP'
export const GET_SIZEGROUP = 'GET_SIZEGROUP'
export const EDIT_SIZEGROUP = 'EDIT_SIZEGROUP'

// USER EMAIL TEMPLATE
export const GET_USER_EMAIL_TEMPLATES = 'GET_USER_EMAIL_TEMPLATES'
export const BEFORE_USER_EMAIL_TEMPLATE = 'BEFORE_USER_EMAIL_TEMPLATE'
export const DELETE_USER_EMAIL_TEMPLATE = 'DELETE_USER_EMAIL_TEMPLATE'
export const CREATE_USER_EMAIL_TEMPLATE = 'CREATE_USER_EMAIL_TEMPLATE'
export const GET_USER_EMAIL_TEMPLATE = 'GET_USER_EMAIL_TEMPLATE'
export const EDIT_USER_EMAIL_TEMPLATE = 'EDIT_USER_EMAIL_TEMPLATE'
export const GET_USER_EMAIL_TYPES = 'GET_USER_EMAIL_TYPES'

// USER EMAIL TEMPLATE
export const GET_EMAIL_TYPES = 'GET_EMAIL_TYPES'
export const BEFORE_EMAIL_TYPE = 'BEFORE_EMAIL_TYPE'
export const DELETE_EMAIL_TYPE = 'DELETE_EMAIL_TYPE'
export const CREATE_EMAIL_TYPE = 'CREATE_EMAIL_TYPE'
export const GET_EMAIL_TYPE = 'GET_EMAIL_TYPE'
export const EDIT_EMAIL_TYPE = 'EDIT_EMAIL_TYPE'

// PRODUCTS
export const BEFORE_PRODUCT = 'BEFORE_PRODUCT'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCT = 'GET_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'

// SPECIAL PRODUCTS
export const BEFORE_SPECIAL_PRODUCT = 'BEFORE_SPECIAL_PRODUCT'
export const CREATE_SPECIAL_PRODUCT = 'CREATE_SPECIAL_PRODUCT'
export const GET_SPECIAL_PRODUCTS = 'GET_SPECIAL_PRODUCTS'
export const GET_SPECIAL_PRODUCT = 'GET_SPECIAL_PRODUCT'
export const DELETE_SPECIAL_PRODUCT = 'DELETE_SPECIAL_PRODUCT'
export const EDIT_SPECIAL_PRODUCT = 'EDIT_SPECIAL_PRODUCT'

// PRODUCTS VARIATIONS
export const CREATE_VARIATION = 'CREATE_VARIATION'
export const GET_PRODUCT_VARIATIONS = 'GET_PRODUCT_VARIATIONS'
export const DELETE_PRODUCT_VARIATION = 'DELETE_PRODUCT_VARIATION'
export const EDIT_PRODUCT_VARIATION = 'EDIT_PRODUCT_VARIATION'

// ACTIVITY
export const BEFORE_ACTIVITY = 'BEFORE_ACTIVITY'
export const GET_ACTIVITIES = 'GET_ACTIVITIES'

// DASHBOARD
export const BEFORE_DASHBOARD = 'BEFORE_DASHBOARD'
export const GET_DASHBOARD = 'GET_DASHBOARD'

//CONTENT MANAGEMENT
export const GET_CONTENT_PAGE = 'GET_CONTENT_PAGE'
export const GET_CONTENT_PAGES = 'GET_CONTENT_PAGES'
export const EDIT_CONTENT_PAGE = 'EDIT_CONTENT_PAGE'
export const DELETE_CONTENT_PAGE = 'DELETE_CONTENT_PAGE'
export const ADD_CONTENT_PAGE = 'ADD_CONTENT_PAGE'
export const BEFORE_CONTENT = 'BEFORE_CONTENT'


export const BEFORE_SALE = 'BEFORE_SALE'
export const SELECT_PRODUCT = 'SELECT_PRODUCT'
export const SELECT_QUANTITY = 'SELECT_QUANTITY'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const GET_SALE_PRODUCTS = 'GET_SALE_PRODUCTS'
export const CEATE_SALE = 'CEATE_SALE'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDER = 'GET_ORDER'
export const UPSERT_ORDER = 'UPSERT_ORDER'
export const UPSERT_ORDER_STATUS = 'UPSERT_ORDER_STATUS'
export const CREATE_INVOICE = 'CREATE_INVOICE'
export const PAYMENT_REGISTERED = 'PAYMENT_REGISTERED'
export const PAYMENT_NOT_REGISTERED = 'PAYMENT_NOT_REGISTERED'
export const DEFAULT_CURRENCY = 'DEFAULT_CURRENCY'

export const BEFORE_REPORT = 'BEFORE_REPORT'
export const GET_SALES_REPORT = 'GET_SALES_REPORT'
export const GET_INVOICE_REPORT = 'GET_INVOICE_REPORT'
export const GET_VAT = 'GET_VAT'


// BUG REPORTING
export const BEFORE_BUG_REPORT = 'BEFORE_BUG_REPORT'
export const GET_BUG_REPORTS = 'GET_BUG_REPORTS'
export const RESPONDED_TO_BUG_REPORT = 'RESPONDED_TO_BUG_REPORT'
// VALIDATE COUPON
export const VALIDATE_COUPON = 'VALIDATE_COUPON'
export const NO_VALIDATE_COUPON = 'NO_VALIDATE_COUPON'




